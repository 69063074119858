import router from '@/router'
import store from './store'

import { getToken } from '@/utils/token'
import { setActiveMenu, setBreadCrumbs } from '@/utils/dom_util'
import { hasPermission } from '@/utils/permission'

const routeLoginName = 'login'
const routeHomeName = 'home'
const route404Name = '404'
// 免登录路由名称白名单
const routerWhiteList = ['login']

router.beforeEach((to, from, next) => {
  // 设置面包屑
  setBreadCrumbs(to)
  // 设置菜单选中(导航菜单高亮)
  setActiveMenu(to)

  // 未登录情况
  if (!getToken()) {
    if (routerWhiteList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({
        name: routeLoginName,
        query: { redirect: to.fullPath }
      })
    }
    return
  }

  // 已登录
  if (to.name === routeLoginName) {
    next({ name: routeHomeName })
    return
  }

  // 没有获取身份及权限信息，调用api获取
  if (store.getters.userId === 0) {
    // 获取个人信息及权限
    store.dispatch('FindUserInfo')
      .then(() => {
        // 需要验证权限，进行验证
        if (hasPermission(to.meta.permission)) {
          next()
        } else {
          next({ name: route404Name })
        }
      })
      .catch(() => {
        // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
        store.dispatch('ClearLoginInfo').then(() => {
          next({
            name: routeLoginName,
            query: { redirect: to.fullPath }
          })
        })
      })
  } else {
    if (hasPermission(to.meta.permission)) {
      next()
    } else {
      next({ name: route404Name })
    }
  }
})

