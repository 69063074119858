import store from '@/store'

// 设置面包屑
export const setBreadCrumbs = function(route) {
  const breadList = route.matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
  store.dispatch('SetDynamicBreadCrumbs', breadList).then(() => {})
}

// 设置菜单选中(导航菜单高亮)
export const setActiveMenu = function(route) {
  // 查找所有包含 meta.menu 的嵌套路由
  const routes = route.matched.filter(item => item.meta && item.meta.menu)
  if (routes.length > 0) {
    // 设置所有出现的 menu
    const menus = routes.map(item => item.meta.menu)
    store.dispatch('SetCurrentMenu', menus).then(() => {})
  } else {
    store.dispatch('SetCurrentMenu', []).then(() => {})
  }
}
