import Vue from 'vue'

import lodash from 'lodash'
import moment from 'moment'
// echarts 按需加载
import * as echarts from 'echarts/core'
// 引入主题配置文件
import echartsDark from '@/utils/echarts_dark.json'
import {
  DatasetComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  PolarComponent,
  RadarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from 'echarts/components'
import { LineChart, PieChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

// 按需引入 antd 组件
import {
  Breadcrumb,
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Switch,
  Table,
  Tooltip,
  Upload
} from 'ant-design-vue'

Vue.use(Breadcrumb)
Vue.use(Button)
Vue.use(Col)
Vue.use(ConfigProvider)
Vue.use(DatePicker)
Vue.use(Descriptions)
Vue.use(Divider)
Vue.use(Dropdown)
Vue.use(Empty)
Vue.use(Form)
Vue.use(Icon)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Modal)
Vue.use(Pagination)
Vue.use(Row)
Vue.use(Select)
Vue.use(Space)
Vue.use(Spin)
Vue.use(Statistic)
Vue.use(Switch)
Vue.use(Table)
Vue.use(Tooltip)
Vue.use(Upload)

echarts.use(
  [
    CanvasRenderer,
    DatasetComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    MarkAreaComponent,
    PieChart,
    PolarComponent,
    RadarComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    UniversalTransition,
    DataZoomInsideComponent,
    DataZoomSliderComponent
  ]
)

// 引入echarts全局自定义主题
echarts.registerTheme('echarts_dark', echartsDark)

Vue.prototype.$primaryColor = '#00b7ff'
// 对于双色图标，可以通过使用 getTwoToneColor() 和 setTwoToneColor(colorString) 来全局设置图标主色。
Icon.setTwoToneColor(Vue.prototype.$primaryColor)

Vue.prototype.$message = message
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$success = Modal.success
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$echarts = echarts
Vue.prototype.$lodash = lodash
Vue.prototype.$moment = moment
