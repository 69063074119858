<template>
  <div v-if="breadList.length > 0" class="breadcrumb">
    <a-breadcrumb separator=">>" style="line-height: 50px">
      <a-breadcrumb-item
        v-for="(routeRecord, index) in breadList"
        :key="routeRecord.path"
      >
        <a-tooltip :mouse-enter-delay="1" placement="top">
          <template slot="title">
            {{ formatTitle(routeRecord) }}
          </template>
          <a
            v-if="isRouteCanClick(routeRecord) && index !== breadList.length - 1"
            @click.prevent="handleLink(routeRecord)"
          >{{ formatTitle(routeRecord) | ellipsis }}</a>
          <span
            v-else
          >{{ formatTitle(routeRecord) | ellipsis }}</span>
        </a-tooltip>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
import { compile } from 'path-to-regexp'

export default {
  name: 'BreadCrumb',
  computed: {
    breadList() {
      return this.$store.getters.dynamicBreadCrumbs.routes
    },

    breadPlaceholder() {
      return this.$store.getters.dynamicBreadCrumbs.placeholder
    }
  },

  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0, 10) + '...'
      }
      return value
    }
  },

  methods: {
    formatTitle(routeRecord) {
      if (routeRecord.meta.title.startsWith(':')) {
        // 有占位符, 需要替换值
        if (this.breadPlaceholder[routeRecord.meta.title]) {
          return this.breadPlaceholder[routeRecord.meta.title]
        } else {
          return '...'
        }
      } else {
        return routeRecord.meta.title
      }
    },

    isRouteCanClick(routeRecord) {
      if (routeRecord.redirect === 'noRedirect') {
        return false
      }

      return routeRecord.name !== this.$route.name && routeRecord.path !== this.$route.path
    },

    pathCompile(path) {
      // 解决路由中参数问题 path: ':id/details'
      const { params } = this.$route
      const toPath = compile(path)
      return toPath(params)
    },

    handleLink(item) {
      const {
        redirect,
        path
      } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    }
  }
}
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 50px;
  margin-bottom: 20px;
  line-height: 50px;
}
</style>
