<template>
  <a-layout-content class="se-flex se-flex-col">
    <bread-crumb />
    <div class="se-flex-1 content">
      <router-view />
    </div>
  </a-layout-content>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'LayoutContent',
  components: { BreadCrumb }
}
</script>

<style lang="less" scoped>
.content {
  box-shadow: 0 0 10px #ccc;
}

/deep/ .ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: unset !important;
}
</style>
