const getters = {
  userId: state => state.user.id,
  userFullName: state => state.user.fullName,
  permissions: state => state.user.permissions,
  isPlatformUser: state => ['platform_super_admin', 'platform_admin'].includes(state.user.role),
  dynamicBreadCrumbs: state => state.app.dynamicBreadCrumbs,
  currentMenus: state => state.app.currentMenus
}

export default getters
