<template>
  <div class="bg">
    <a-layout class="layout">
      <layout-header />

      <a-layout
        :has-sider="true"
        class="section"
      >
        <layout-sider />
        <a-layout-content class="se-flex se-flex-col">
          <bread-crumb />
          <div class="se-flex-1">
            <router-view />
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import LayoutHeader from '@/components/LayoutHeader/index'
import LayoutSider from '@/components/LayoutSider/index'
import BreadCrumb from '@/components/BreadCrumb'

export default {
  name: 'CustomerLayout',
  components: {
    LayoutHeader,
    LayoutSider,
    BreadCrumb
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/variables";

.bg {
  width: 100vw;
  height: 100vh;
  min-width: 1366px;
  overflow: hidden;
}

// 内部 layout 相关
.layout {
  height: 100%;

  .section {
    padding: @section-padding;
  }
}
</style>
