<template>
  <div class="bg">
    <a-layout class="layout">
      <layout-header />

      <a-layout
        class="section"
        :has-sider="true"
      >
        <layout-sider />
        <layout-content />
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import LayoutHeader from '@/components/LayoutHeader/index'
import LayoutSider from '@/components/LayoutSider/index'
import LayoutContent from '@/components/LayoutContent/index'

export default {
  name: 'BasicLayout',
  components: {
    LayoutHeader,
    LayoutSider,
    LayoutContent
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/variables";

.bg {
  width: 100vw;
  height: 100vh;
  min-width: 1366px;
  overflow: hidden;
}

// 内部 layout 相关
.layout {
  height: 100%;

  .section {
    padding: @section-padding;
  }
}
</style>
