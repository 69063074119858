<template>
  <a-layout-header
    class="header"
    theme="dark"
  >
    <a-row class="inner-box">
      <a-col
        :span="8"
        class="se-height-100p se-flex se-items-center"
      >
        <a-space>
          <div v-if="logoUrl" class="logo se-flex se-items-center se-justify-center">
            <img :src="logoUrl" alt="">
          </div>
          <h1 class="se-margin-0 se-ellipsis">智峰电气设备管理平台</h1>
        </a-space>
      </a-col>

      <a-col
        :offset="8"
        :span="8"
        class="se-height-100p se-flex se-justify-end se-align-items-center"
      >
        <div class="blocks-container se-flex se-justify-end se-align-items-center se-primary-color">
          <div class="user-info-wrapper">
            <a-dropdown placement="bottomRight">
              <a-space>
                <a-icon type="user" />
                <a-tooltip :mouse-enter-delay="1" :title="this.$store.getters.userFullName" placement="top">
                  <span class="se-ellipsis se-word-break">{{ this.$store.getters.userFullName }}</span>
                </a-tooltip>
              </a-space>

              <a-menu slot="overlay">
                <a-menu-item key="info" @click="showPersonalInformationModal">
                  <a-space>
                    <a-icon type="idcard" />
                    个人信息
                  </a-space>
                </a-menu-item>

                <a-menu-item
                  key="logout"
                  @click="handleLogout"
                >
                  <a-space>
                    <a-icon type="logout" />
                    退出登录
                  </a-space>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </a-col>
    </a-row>
    <!--   个人信息模态框   -->
    <personal-information
      v-if="isShowPersonalInformationModal"
      :visible.sync="isShowPersonalInformationModal"
    />
  </a-layout-header>
</template>

<script>
export default {
  name: 'LayoutHeader',
  components: {
    PersonalInformation: () => import('@/components/LayoutHeader/PersonalInformation')
  },
  data() {
    return {
      isShowPersonalInformationModal: false
    }
  },
  computed: {
    logoUrl() {
      return this.$store.getters.logoUrl
    }
  },
  methods: {
    handleLogout() {
      return this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    },

    showPersonalInformationModal() {
      this.isShowPersonalInformationModal = true
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  height: 75px;
  background-color: rgba(25, 27, 61, 1);
  background-size: 100% 280%;
  -webkit-background-size: 100% 280%;
  background-repeat: no-repeat;

  // header 背景图下面一条线视觉上感觉是header边界，这边让内部元素抬高
  .inner-box {
    height: calc(100% - 5px);

    h1 {
      color: @white;
    }

    .logo {
      height: 37.5px;
      width: 90px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .blocks-container {
      padding: 6px 0;
      line-height: initial;
      color: @primary-color;

      .user-info-wrapper {
        width: 130px;
        font-size: 16px;
        padding-top: 20px;
        color: @white;
      }
    }
  }
}
</style>
