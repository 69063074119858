import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from '@/layouts/Basic'
import CustomerLayout from '@/layouts/Customer'

Vue.use(VueRouter)

// meta 部分说明
// 如 meta: { title: '用户管理', permission: 'users', menu: 'users', breadcrumb: false }
// permission 用于权限控制，api 返回的权限列表中有 users 才可以访问
// menu       用于菜单选中高亮，如果不是菜单则可以不传值，值需要和 a-menu-item key="users" 的key值相同
// title      用于浏览器 tab 显示以及面包屑显示
//            - 以 ':' 开头的 title 为动态的值占位符, 用作业务逻辑中的值替换
//              可使用 SetDynamicBreadCrumbsPlaceholder 方法替换占位符的值, 如：
//              this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':agent_name': '客户1' })
//            - 还提供了 ConcatDynamicBreadCrumbs 方法追加面包屑, 常用于面包屑生成规则不统一，自行生成面包屑
// breadcrumb 设置为 false 时，面包屑不会显示

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/',
    component: CustomerLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: {
          breadcrumb: false,
          menu: 'home'
        }
      }
    ]
  },
  {
    path: '/customers',
    component: BasicLayout,
    children: [
      {
        path: '/customers',
        name: 'customers',
        component: () => import('@/views/customers/index'),
        meta: {
          title: '客户管理',
          permission: 'admin/customers',
          menu: 'customers'
        }
      }
    ]
  },
  {
    path: '/gateways',
    component: CustomerLayout,
    meta: {
      title: '集抄器',
      menu: 'devices'
    },
    children: [
      {
        path: '/gateways',
        name: 'gateways',
        component: () => import('@/views/gateway/index'),
        meta: {
          permission: 'admin/gateways',
          menu: 'gateways'
        }
      },
      {
        path: ':id',
        name: 'gateway_details',
        component: () => import('@/views/gateway/details/index'),
        meta: {
          title: '集抄器详情',
          menu: 'gateways',
          permission: 'admin/gateways'
        }
      }
    ]
  },
  {
    path: '/terminals',
    component: CustomerLayout,
    meta: {
      title: '终端设备',
      menu: 'devices'
    },
    children: [
      {
        path: '/terminals',
        name: 'terminals',
        component: () => import('@/views/terminals/index'),
        meta: {
          permission: 'admin/terminals',
          menu: 'terminals'
        }
      },
      {
        path: ':id',
        name: 'terminal_details',
        component: () => import('@/views/terminals/details/index'),
        meta: {
          title: '终端设备详情',
          menu: 'terminals',
          permission: 'admin/terminals'
        }
      }
    ]
  },
  {
    path: '/users',
    component: BasicLayout,
    meta: { menu: 'system_config' },
    children: [
      {
        path: '/users',
        name: 'users',
        component: () => import('@/views/admin/users/index'),
        meta: {
          title: '用户管理',
          permission: 'admin/users',
          menu: 'users'
        }
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
