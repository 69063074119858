<template>
  <a-layout-sider class="sider" width="160">
    <layout-sider-platform />
  </a-layout-sider>
</template>

<script>
export default {
  name: 'LayoutSider',
  components: {
    LayoutSiderPlatform: () => import('@/components/LayoutSider/PlatformMenus')
  }
}
</script>

<style lang="less" scoped>
.sider {
  margin-right: 20px;
  overflow: auto;
  box-shadow: 0 0 10px #ccc;
  background-color: @white;

  // 滚动条
  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>
